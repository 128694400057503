.footer {
    background: #001f3f;
    color: #fff;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .footerText {
    font-size: 1rem;
    color: #ddd;
  }
  
  .socialLinks {
    display: flex;
    gap: 1.2rem;
  }
  
  .socialIcon {
    font-size: 1.8rem;
    color: #FFD700;
    transition: color 0.3s ease;
  }
  
  .socialIcon:hover {
    color: #FFB300;
  }
  
  .footerBottom {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    color: #aaa;
  }
  
  .footerSmallText a {
    color: #FFD700;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
  }
  
  .footerSmallText a:hover {
    color: #FFB300;
  }
  
  /* Desktop View */
  @media (min-width: 768px) {
    .footerTop {
      justify-content: space-between;
    }
  }
  