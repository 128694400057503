.form {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 36rem;
    width: 100%;
  }
  
  .formGroup {
    margin-bottom: 1.5rem;
  }
  
  .label {
    display: block;
    font-size: 1rem;
    color: #555;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .input,
  .textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    font-size: 1rem;
    color: #333;
  }
  
  .input:focus,
  .textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .textarea {
    resize: vertical;
  }
  
  .checkboxWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .checkbox {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    accent-color: #007bff; /* Modern browsers */
  }
  
  .checkboxLabel {
    font-size: 1rem;
    color: #555;
  }
  
  .submitButton {
    width: 100%;
    background-color: #007bff;
    color: #ffffff;
    padding: 0.75rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .submitButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }
  