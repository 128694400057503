/* Main container for the section */
.webDevSection {
    background: #fff;
    padding: 4rem 2rem;
    color: #001f3f;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .icon {
    font-size: 2.5rem;
    color: #0074D9;
  }
  
  .title {
    font-size: 2rem;
    font-weight: 700;
    color: #0074D9;
  }
  
  .intro {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .toolsSection {
    margin-bottom: 3rem;
  }
  
  .subTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .toolsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
  }
  
  .toolItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #0074D9;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .toolItem:hover {
    transform: translateY(-5px);
  }
  
  .cta {
    margin-top: 3rem;
    text-align: center;
  }
  
  .ctaText {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .ctaButton {
    background-color: #FFD700;
    color: #001f3f;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .ctaButton:hover {
    background-color: #FFB300;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .webDevSection {
      padding: 4rem 3rem;
    }
  }
  