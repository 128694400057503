.hero {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 2rem 1rem;
    background: linear-gradient(90deg, #001f3f, #0074D9);
    color: #fff;
    gap: 2rem;
    text-align: center;
  }
  
  .content {
    max-width: 600px;
  }
  
  .title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.2;
  }
  
  .highlight {
    color: #FFD700;
  }
  
  .subtitle {
    margin-top: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #ddd;
  }
  
  .cta {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .btn {
    text-decoration: none;
    padding: 0.8rem 1.6rem;
    font-weight: 600;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
  
  .primaryBtn {
    background: #FFD700;
    color: #001f3f;
  }
  
  .primaryBtn:hover {
    background: #FFB300;
  }
  
  .secondaryBtn {
    background: transparent;
    border: 2px solid #FFD700;
    color: #FFD700;
  }
  
  .secondaryBtn:hover {
    background: #FFD700;
    color: #001f3f;
  }
  
  .imageContainer {
    width: 100%;
    max-width: 500px;
  }
  
  .heroImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Desktop view */
  @media (min-width: 768px) {
    .hero {
      flex-direction: row;
      justify-content: space-between;
      padding: 4rem 2rem;
      text-align: left;
    }
  
    .content {
      max-width: 600px;
    }
  
    .title {
      font-size: 3rem;
    }
  
    .cta {
      justify-content: flex-start;
    }
  }
  