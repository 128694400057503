.salesforceSection {
    background: #f7f7f7;
    padding: 4rem 2rem;
    color: #001f3f;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .icon {
    font-size: 2.5rem;
    color: #0074D9;
  }
  
  .title {
    font-size: 2rem;
    font-weight: 700;
    color: #0074D9;
  }
  
  .intro {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .cta {
    margin-top: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .ctaText {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .ctaButton {
    background-color: #FFD700;
    color: #001f3f;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
    text-align: center;
  }
  
  .ctaButton:hover {
    background-color: #FFB300;
  }
  
  @media (min-width: 768px) {
    .salesforceSection {
      padding: 4rem 3rem;
    }
  
    .cta {
      margin-top: 3rem;
    }
  }
  