.container {
    background-color: #f7f7f7;
    min-height: 100vh;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .subHeader {
    font-size: 1.125rem;
    color: #666;
    margin-bottom: 3rem;
    text-align: center;
    line-height: 1.5;
    max-width: 40rem;
  }
  