.contact {
    padding: 3rem 1rem;
    background: #001f3f;
    color: #fff;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sectionTitle {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #FFD700;
  }
  
  .sectionSubtitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #ddd;
  }
  
  .contactMethods {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .contactMethod {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contactIcon {
    font-size: 2rem;
    color: #FFD700;
  }
  
  .contactLink {
    font-size: 1.1rem;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .contactLink:hover {
    color: #FFD700;
  }
  
  .cta {
    margin-top: 2rem;
    font-size: 1.2rem;
  }
  
  .ctaButton {
    padding: 0.6rem 1.2rem;
    background: #FFD700;
    color: #001f3f;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;
    transition: background 0.3s ease;
  }
  
  .ctaButton:hover {
    background: #FFB300;
  }
  
  /* Desktop View */
  @media (min-width: 768px) {
    .contactMethods {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  