.projects {
    padding: 3rem 1rem;
    background: #001f3f;
    color: #fff;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sectionTitle {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #FFD700;
  }
  
  .sectionSubtitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #ddd;
  }
  
  .projectsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .projectCard {
    /* background: rgba(255, 255, 255, 0.1); */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .projectCard:hover {
    transform: translateY(-10px);
  }
  
  .projectTitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: #FFD700;
    margin-bottom: 1rem;
  }
  
  .projectDescription {
    font-size: 1rem;
    line-height: 1.6;
    color: #ddd;
    margin-bottom: 1rem;
  }
  
  .projectTech {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    padding: 0;
  }
  
  .techItem {
    background: rgba(255, 255, 255, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #fff;
  }
  
  .projectLink {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #FFD700;
    color: #001f3f;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;
    transition: background 0.3s ease;
  }
  
  .projectLink:hover {
    background: #FFB300;
  }

  .buttonContainer {
    margin-top: 2rem;
    text-align: center;
  }
  
  .viewAllButton {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: #001f3f;
    border: 2px solid #FFD700;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .viewAllButton:hover {
    background-color: #FFD700;
    color: #001f3f;
    transform: scale(1.05);
  }
  
  
  /* Desktop view */
  @media (min-width: 768px) {
    .projectsGrid {
      grid-template-columns: repeat(3, 1fr);
    }
  
    .projectCard {
      text-align: center;
    }
  
    .projectLink {
      margin-top: 1rem;
    }
  }
  