.skills {
    padding: 3rem 1rem;
    background: linear-gradient(90deg, #0074D9, #001f3f);
    color: #fff;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sectionTitle {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .sectionSubtitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #ddd;
  }
  
  .skillsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .skillCard {
    background: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .skillTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #FFD700;
    margin-bottom: 0.5rem;
  }
  
  .skillDescription {
    font-size: 1rem;
    line-height: 1.6;
    color: #ddd;
  }
  
  .toolsTitle {
    margin-top: 3rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #FFD700;
  }
  
  .toolsList {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    list-style: none;
    padding: 0;
  }
  
  .toolItem {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    color: #fff;
    font-size: 0.9rem;
  }
  
  /* Desktop view */
  @media (min-width: 768px) {
    .skillsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .skillCard {
      text-align: center;
    }
  
    .toolsList {
      gap: 2rem;
    }
  }
  
  .toolsIcons {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .toolIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .toolIcon svg {
    font-size: 3rem;
    color: #FFD700;
    transition: transform 0.3s ease;
  }
  
  .toolIcon:hover svg {
    transform: scale(1.2);
  }
  
  .toolName {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
  }
  