/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: linear-gradient(90deg, #001f3f, #0074D9);
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .headerLogo h1 {
    font-size: 1.6rem;
    font-weight: 700;
  }
  
  .headerLogo p {
    font-size: 0.8rem;
    color: #ddd;
  }
  
  .menuToggle {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    z-index: 1100;
  }
  
  .menuIcon {
    display: inline-block;
  }

  .linkk{
    text-decoration: none;
  }
  
  .headerNav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background: #001f3f;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .headerNav.open {
    display: flex;
  }
  
  .navList {
    list-style: none;
    padding: 1rem;
  }
  
  .navList li {
    padding: 0.5rem 0;
    text-align: center;
  }
  
  .navList a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    transition: color 0.3s;
  }
  
  .navList a:hover {
    color: #FFD700;
  }
  
  .headerButtons {
    display: none; /* Hide buttons in mobile */
  }
  
  .btn {
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    font-weight: 600;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    text-align: center;
  }
  
  .primaryBtn {
    background: #FFD700;
    color: #001f3f;
  }
  
  .primaryBtn:hover {
    background: #FFB300;
  }
  
  .secondaryBtn {
    background: transparent;
    border: 2px solid #FFD700;
    color: #FFD700;
  }
  
  .secondaryBtn:hover {
    background: #FFD700;
    color: #001f3f;
  }
  
  /* Desktop view (Media Query) */
  @media (min-width: 768px) {
    .menuToggle {
      display: none;
    }
  
    .header {
      justify-content: space-between;
    }
  
    .headerNav {
      display: flex;
      flex-direction: row;
      position: static;
      background: none;
      width: auto;
      box-shadow: none;
    }
  
    .navList {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      padding: 0;
    }
  
    .navList li {
      padding: 0;
      text-align: left;
    }
  
    .headerButtons {
      display: flex;
      gap: 1rem;
    }
  
    .btn {
      width: auto;
    }
  }
  