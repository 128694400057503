/* General Page Styles */
.projectsPage {
    padding: 3rem 1rem;
    background: #f4f4f4;
    color: #2d3748;
    font-family: 'Inter', sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .pageTitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1a202c;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .pageSubtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #718096;
    line-height: 1.8;
    margin-bottom: 3rem;
  }
  
  /* Grid for Projects */
  .projectsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  /* .projectCard {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  } */
  .projectCard {
    position: relative; /* Make the card a containing block for absolute positioning */
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .projectLogo {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 60px;
    height: 60px;
    object-fit: contain; /* Ensure the logo is not distorted */
    border-radius: 5px; /* Slightly rounded corners */
    background: white; /* Optional background for better contrast */
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); */
  }
  
  
  .projectCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Icon Styles */
  .projectIcon {
    font-size: 4rem;
    color: #3182ce;
    margin-bottom: 1rem;
  }
  
  /* Card Content */
  .projectTitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: #2c5282;
    margin-bottom: 1rem;
  }
  
  .projectDescription {
    font-size: 1rem;
    color: #4a5568;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
 
    /* Features List */
  .featuresList {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1.5rem;
  }

  .featureItem {
    font-size: 0.95rem;
    color: #2d3748;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.8rem; /* Adds space between the icon and text */
  }

  .featureItemIcon {
    flex-shrink: 0;
    font-size: 1.5rem;
    color: #38a169; /* Elegant green for icons */
    background: #e6fffa; /* Soft green background */
    border-radius: 50%;
    padding: 0.3rem; /* Adds some padding around the icon */
  }


  
  .techStack {
    margin-top: 1rem;
    font-size: 0.95rem;
    color: #4a5568;
  }
  
  .techBadge {
    display: inline-block;
    background: #ebf8ff;
    color: #3182ce;
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
    font-size: 0.85rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  /* CTA Section Styles */
.ctaContainer {
    background-color: #3182ce;
    color: white;
    padding: 3rem 1rem;
    text-align: center;
    margin-top: 3rem;
    border-radius: 10px;
  }
  
  .ctaTitle {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .ctaSubtitle {
    font-size: 1.1rem;
    line-height: 1.7;
    margin-bottom: 2rem;
  }
  
  .ctaButton {
    background-color: #f6ad55;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .ctaButton:hover {
    background-color: #f56565;
    cursor: pointer;
  }
  
  .ctaButton:focus {
    outline: none;
  }
  