/* Ensure the body and html take the full height of the screen */
html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Main container using Flexbox to push footer to the bottom */
.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes up at least the full height of the viewport */
}

/* Content section that grows to fill available space */
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

/* Heading style */
.content h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

/* Paragraph style */
.content p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

/* Links to navigate around */
.content a {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
}

.content a:hover {
  text-decoration: underline;
}

/* Footer styling */
.footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
  font-size: 0.875rem;
}

/* Optional styling for the footer, adjusting spacing and mobile responsiveness */
.footer p {
  margin: 0;
}
